import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {AiFillFacebook} from 'react-icons/ai'
import {AiFillInstagram} from 'react-icons/ai'

const HeaderSocials = () => {
  return (
    <div className="header__socials">
        <a href='https://www.linkedin.com/in/lisa-mcintosh-461b36222/' target='_blank'><BsLinkedin /></a>
        <a href='https://www.facebook.com/profile.php?id=100091797606587' target='_blank'><AiFillFacebook /></a>
        <a href='https://www.instagram.com/lisamcintoshva/' target='_blank'><AiFillInstagram /></a>
        {/* <a href='https://www.linkedin.com/in/lisa-mcintosh-461b36222/' target='_blank'></a>
        <a href='https://www.linkedin.com/in/lisa-mcintosh-461b36222/' target='_blank'></a> */}
    </div>
  )
}

export default HeaderSocials