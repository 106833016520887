import React from 'react'
import './testimonials.css'
import Spuddy from '../../assets/SpuddySquareSml.png'
import Kerri from '../../assets/Kerri_sml.jpg'
import PaulV from '../../assets/PaulV.jpg'
import Narelle from '../../assets/narelle.png'
import Erin from '../../assets/Erin.jpg'

// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


const data = [
  {
    avatar: Spuddy,
    name: "Anthony W.",
    review: "Lisa’s communication is excellent and she also has a high understanding of business processes. Lisa handled everything with a professional manner and an infectious smile, making her not only a valuable asset for the company but a joy for our clientele and staff.  In my 21 years of running my own business, I could not speak more highly of the efforts and commitment Lisa gave to the work she was involved in.",
  }, 
  {
    avatar: Kerri,
    name: "Kerri P.",
    review: "Lisa brings a level of professionalism and attention to detail that makes the necessary administrative functions work efficiently. Her customer service skills even in difficult and confronting circumstances are exceptional" ,
  },
  // {
  //   avatar: PaulV,
  //   name: "Paul V.",
  //   review: "Lisa has been an invaluable contributer to the success of my contracting business. She has a way with people that is second to none, my clients love her and she treats my business as though it is her own. Her skill set is wide and varied, and what she does not know, she is willing to learn and adapt. My only regret is not having enough work to hire her full time" ,
  // },
  {
    avatar: Narelle,
    name: "Narelle R.",
    review: "Lisa is one of the most dedicated, hardworking and innovative people I've had the pleasure of working with in any capacity. Lisa is also compassionate, kind and never misses the opportunity to help others. Lisa is the type of person you can count on to keep a cool head in a stressful situation, and her positive attitude is contagious." ,
  },
  {
    avatar: Erin,
    name: "Erin T.",
    review: "Lisa has been a lifesaver to my business. She is exceptional at implementing processes and procedures that has not only saved me time in scheduling appointments but is very easy to follow. Lisa’s attention to detail and her ability to present reports and documents has helped my business phenomenally" ,
  },
]


const Testimonials = () => {
  return (
    <section id="testimonials">
      <h5>Review from Clients</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials__container"
      modules={[Pagination]}
      spaceBetween={40}
      centeredSlides={true}
      parallax={true}
      slidesPerView={1}
      navigation
      loop={true}
      pagination={{ clickable: true }}>
{
  data.map(({avatar, name, review}, index) => {
    return (
      <SwiperSlide key={index} className="testimonial">
          <div className="client_avatar">
            <img src={avatar} />
          </div>
          <h5 className='client__name'>{name}</h5>
          <small className='client__review'>{review}</small>
        </SwiperSlide>
    )
  })
}

        
        
      </Swiper>
    </section>
  )
}

export default Testimonials