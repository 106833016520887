import React from 'react'
import Header from './components/header/Header'
import Nav from './components/nav/Nav'
import About from './components/about/About'
import Experience from './components/experience/Experience'
import Services from './components/services/Services'
import Pricing from './components/pricing/Pricing'
import Testimonials from './components/testimonials/Testimonials'
import Contacts from './components/contacts/Contacts'
import Footer from './components/footer/Footer'
import Technology from './components/technology/Technology'

const App = () => {
  return (
   <>
    <Header />
    <Nav />
    <About />
    {/* <Experience /> */}
    <Services />
    <Technology />
    {/* <Portfolio /> */}
    {/* <Pricing /> */}
    <Testimonials />
    <Contacts />
    <Footer />

   </>
  )
}

export default App