import React from 'react'
import CTA from './CTA'
import './header.css';
import Lisa from '../../assets/Purple-noBG-med.png'
import HeaderSocials from './HeaderSocials';

const Header = () => {
  return (
    <header>
        <div className="container header__container">
            <h5>Hello, I'm</h5>
            <h1>Lisa McIntosh</h1>
            <h5 className="text-light">Virtual Assistant</h5>
            <CTA />
            <HeaderSocials />
            <div className="me">
                <img src={Lisa} alt="Lisa - Virtual Assistant" />
            </div>
            <a href="#contact" className='scroll__down'>Scroll Down</a>
        </div>
    </header>
  )
}

export default Header