
import React from 'react'
import './technology.css' //
import {BsCheckLg} from 'react-icons/bs'

const Technology = () => {
  return (
    <section id="technology">
      <h5>Technology</h5>
      <h2>Software and hardware I use</h2>
      <div className="container services__container">
        <article className="service">
          <ul className="service__list">
            <li><BsCheckLg className='service__list-icon'/><p>High-powered modern laptop</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Mobile phone</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>High quality headset with noise reduction and AI-assisted background noise filter</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Fast primary internet connection</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Fully licensed Microsoft and Google suite of tools</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Minimum 4G backup internet mobile connection</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Secure office space</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Encrypted communications through a VPN to protect your data</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Large capacity Secure online storage for uploads and downloads as required</p></li>
          </ul>
        </article>
      </div>
    </section>
  )
}

export default Technology