import React from 'react'
import './contacts.css' // MdOutlineEmail
import {MdOutlineEmail} from 'react-icons/md'
import {FaFacebookMessenger} from 'react-icons/fa'
import {BsWhatsapp} from 'react-icons/bs' 
import { useRef, useState } from 'react';
import emailjs from 'emailjs-com';

const Contacts = () => {

  const form = useRef();
  const [showModal, setShowModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_5pa3qhs', 'template_bzt5sx4', form.current, 'kqIAwDzRRMB8a3xiq') //'kqIAwDzRRMB8a3xiq'
    .then((result) => {
      console.log(result.text);
      setShowModal(true);
      setIsSuccess(true);
      setTimeout(() => {
        setShowModal(false);
        setIsSuccess(false);
      }, 5000);
  }, (error) => {
      console.log(error.text);
      setShowModal(true);
      setIsSuccess(false);
      setTimeout(() => {
        setShowModal(false);
        setIsSuccess(false);
      }, 5000);
  });
    e.target.reset();

      
  };

  return (
    <section id="contact">
      <h5>Get in Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>Lisa@McIntoshVA.com</h5>
            <a href='mailto:lisa@mcintoshva.com' target='_blank'>Send a message</a>
          </article>
          <article className="contact__option">
            <FaFacebookMessenger className='contact__option-icon' />
            <h4>Messenger</h4>
            <h5>McIntosh VA</h5>
            <a href='https://www.facebook.com/profile.php?id=100091380296408'target='_blank'>Message me on FB</a>
          </article>
          <article className="contact__option">
            <BsWhatsapp className='contact__option-icon' />
            <h4>WhatsApp</h4>
            <h5>Lisa McIntosh</h5>
            <a href='https://wa.me/+61405119812' target='_blank'>Message me on WhatsApp</a>
          </article>
        </div>
        {/* end of contact options */}
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="name" placeholder='Your Full Name' required />
          <input type="email" name="email" placeholder='Your email' required />
          <textarea name="message" rows="7" placeholder='Your message here' required></textarea>
          <button type="submit" className='btn btn-primary'>Send Message</button>
          {showModal && (
            <div className={`modal ${isSuccess ? 'success' : 'error'}`}>
              <p>{isSuccess ? (
                <React.Fragment>
                  Your message has been sent successfully.<br />
                  We will be in contact shortly.
                </React.Fragment>
              ) : (
                'Oops! Something went wrong. Please try again.'
              )}</p>
            </div>
          )}
        </form>
        
      </div>
    </section>
  )
}

export default Contacts