import React from 'react'
import './footer.css' 
import {BsFacebook} from 'react-icons/bs'
import {BsInstagram} from 'react-icons/bs'
import {AiOutlineLinkedin} from 'react-icons/ai' //BsWhatsapp
import {BsWhatsapp} from 'react-icons/bs'

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'>Lisa McIntosh - Virtual Assistant</a>
      <ul className="permalinks">
        <li><a href='#'>Home</a></li>
        <li><a href='#about'>About</a></li>
        {/* <li><a href='#experience'>Experience</a></li> */}
        <li><a href='#services'>Services</a></li>
        <li><a href='#technology'>My Technology</a></li>
        {/* <li><a href='#Pricing'>Pricing</a></li> */}
        <li><a href='#testimonials'>Testimonials</a></li>
        <li><a href='#contact'>Contact</a></li>
      </ul>

    <div className="footer__socials">
      <a href='https://www.facebook.com/profile.php?id=100091797606587' target='_blank'><BsFacebook /></a>
      <a href='https://www.instagram.com/lisamcintoshva/' target='_blank'><BsInstagram /></a>
      <a href='https://www.linkedin.com/in/lisa-mcintosh-461b36222/' target='_blank'><AiOutlineLinkedin /></a>
      <a href='https://wa.me/+61405119812' target='_blank'><BsWhatsapp /></a> 
    </div>
    
    <div className="footer__copyright">
      <small>&copy; Lisa McIntosh Virtual Assitant. All rights reserved.</small>
    </div>

    </footer>
  )
}

export default Footer