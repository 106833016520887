import React from 'react'
import './nav.css'
import {AiOutlineHome} from 'react-icons/ai';
import {BsFillPersonFill} from 'react-icons/bs';
import {AiOutlineLaptop} from 'react-icons/ai';
import {RiCustomerService2Line} from 'react-icons/ri';
import {SiGooglemessages} from 'react-icons/si';
import { useState } from 'react';

const Nav = () => {
    const [activeNav, setactiveNav] = useState('#')
  return (
    <nav>
        <a href="#" onClick={()=> setactiveNav('#')} alt='Home' className={activeNav === '#' ? 'active' : '' }><AiOutlineHome/></a>
        <a href="#about" onClick={()=> setactiveNav('#about')} alt='About me' className={activeNav === '#about' ? 'active' : '' }><BsFillPersonFill/></a>
        <a href="#services" onClick={()=> setactiveNav('#services')} alt='Services' className={activeNav === '#services' ? 'active' : '' }><RiCustomerService2Line/></a>
        <a href="#technology" onClick={()=> setactiveNav('#technology')} alt='My Technology' className={activeNav === '#technology' ? 'active' : '' }><AiOutlineLaptop/></a>
        <a href="#contact" onClick={()=> setactiveNav('#contact')} alt='Contact' className={activeNav === '#contact' ? 'active' : '' }><SiGooglemessages/></a>
    </nav>
  )
}

export default Nav