import React from 'react'
import './services.css' //
import {BsCheckLg} from 'react-icons/bs'

const Services = () => {
  return (
    <section id="services">
      <h5>What I offer</h5>
      <h2>Services</h2>
      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>ADMINISTRATIVE SUPPORT</h3>
          </div>
          <ul className="service__list">
            <li><BsCheckLg className='service__list-icon'/><p>Email management</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Calendar management</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Scheduling appointments</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Booking travel and accommodation</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Collate and distribute meeting documents</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Task management and To-Do lists</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Word processing</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Mail out's</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Office supplies and equipment orders</p></li>
          </ul>
        </article>
        {/* End of secrion 1 */}
        <article className="service">
          <div className="service__head">
            <h3>CUSTOMER SERVICE</h3>
          </div>
          <ul className="service__list">
            <li><BsCheckLg className='service__list-icon'/><p>Onboarding support for staff and clients</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Technical support</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>E-commerce support</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Chat support</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Email support</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Create KPI’s for contact center staff</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Manage and coach staff as required</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Implement a call monitoring guidelines</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Create scripting for call centre staff</p></li>
          </ul>
        </article>
        {/* <article className="service">
          <div className="service__head">
            <h3>TELEPHONY</h3>
          </div>
          <ul className="service__list">
            <li><BsCheckLg className='service__list-icon'/><p>Create KPI’s for call center staff</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Manage and coach staff as required</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Implement a call monitoring system</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Create scripting for call center staff</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Create a systems for business knowledge</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Maintain these systems and keep the data up to date</p></li>
          </ul>
        </article> */}
        {/* End of section 2 */}
        <article className="service">
          <div className="service__head">
            <h3>DOCUMENT FORMATTING AND MANAGEMENT</h3>
          </div>
          <ul className="service__list">
            <li><BsCheckLg className='service__list-icon'/><p>Create business forms and document templates</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Create and maintain policies and procedures</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Create reports, manuals and workbooks</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Formatting and creating templates</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Proof-reading and editing</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>PDF conversions</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Online filing and archiving</p></li>
          </ul>
        </article>
        {/* End of section 3 */}
        <article className="service">
          <div className="service__head">
            <h3>DATA MANAGEMENT</h3>
          </div>
          <ul className="service__list">
            <li><BsCheckLg className='service__list-icon'/><p>CRM and Data management</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Data entry</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Archive Management</p></li>
          </ul>
          <div className="service__head">
            <h3>ACCOUNTS RECEIVABLE/PAYABLE</h3>
          </div>
          <ul className="service__list">
            <li><BsCheckLg className='service__list-icon'/><p>Create and send quotes</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Create and send invoices</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Process bills ready for payment</p></li>
          </ul>
        </article>
        {/* End of section 3 */}
        
        {/* End of section 3 */}
        {/* <article className="service">
          <div className="service__head">
            <h3>ACCOUNTS RECEIVABLE/PAYABLE</h3>
          </div>
          <ul className="service__list">
            <li><BsCheckLg className='service__list-icon'/><p>Create and send quotes</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Create and send invoices</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Debtor management</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Process bills ready for payment</p></li>
          </ul>
        </article> */}
        {/* End of section 3 */}
        <article className="service">
        <div className="service__head">
            <h3>WEB SERVICES</h3>
          </div>
          <ul className="service__list">
            <li><BsCheckLg className='service__list-icon'/><p>Update and maintain business websites</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Basic website creation</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Chat management</p></li>
          </ul>
          <div className="service__head">
            <h3>SOCIAL MEDIA</h3>
          </div>
          <ul className="service__list">
            <li><BsCheckLg className='service__list-icon'/><p>Social media creation</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Social Media Management</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Create budgets and advertising campaigns</p></li>
          </ul>
        </article>
        {/* End of section 3 */}
        {/* <article className="service">
          <div className="service__head">
            <h3>ONBOARDING COORDINATION</h3>
          </div>
          <ul className="service__list">
            <li><BsCheckLg className='service__list-icon'/><p>Students</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Staff</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Clients</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Contractors and Subcontractors</p></li>
          </ul>
        </article> */}
        {/* End of section 3 */}
        
        {/* <article className="service">
          <div className="service__head">
            <h3>WEB SERVICES</h3>
          </div>
          <ul className="service__list">
            <li><BsCheckLg className='service__list-icon'/><p>Updating and maintaining business websites</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Basic website creation</p></li>
            <li><BsCheckLg className='service__list-icon'/><p>Chat management</p></li>
          </ul>
        </article> */}
        {/* End of section 3 */}
      </div>
    </section>
  )
}

export default Services