import React from 'react'
import './about.css'
import ME from '../../assets/lisa.jpg';
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi' 
import {VscFolderLibrary} from 'react-icons/vsc' 

const About = () => {
  return (
    <section id="about">
      <h5>Get to know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="Australia Zoo" />
          </div>
        </div>
        <div className="about__content">
          {/* <div className="about__cards">
            <article className="about__card">
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small>15 years</small>
            </article>
            <article className="about__card">
              <FiUsers className='about__icon'/>
              <h5>Clients</h5>
              <small>7 Clients</small>
            </article>
            <article className="about__card">
              <VscFolderLibrary className='about__icon'/>
              <h5>Projects</h5>
              <small>30+ Completed</small>
            </article>
          </div> */}
          <p>
          With over a decade of professional experience across key
          areas like Account Management, Office Management, and
          Customer Service, I am confident in my ability to make a
          valuable contribution to your team. I currently work with
          individuals, small businesses, and entrepreneurs to take on
          those time-consuming tasks, allowing them to free up time
          and focus on what matters. I possess a keen
          understanding of direction and have a proven track record
          of delivering positive outcomes while establishing strong relationships.
          {/* relationships. I am well-versed in a range of tasks, shown below 
          <a href='#services'> Here </a> */}
          </p>
          <p>
          Everyone has different needs and priorities, I’m here to
          work with you to create bespoke business services that
          help you get back to focusing on what matters most. Have
          complete trust that the job will be done to the highest level
          and always on time.
          </p>
          
          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About